import { plainBtnStyles } from '@/buttons/Plain';
import { css } from '@emotion/react';
import { theme } from './styles/GlobalStyles';

const dynamicStyles = css`
    &[aria-current='page'] {
        color: ${theme.colors.blue4};
    }

    &.current-nav-category {
        color: ${theme.colors.blue4};

        > svg path {
            fill: ${theme.colors.blue4};
        }
    }

    &[data-mobile-expanded],
    &[aria-expanded='true'] {
        > svg {
            transform: rotate(180deg);
        }
    }

    @media (pointer: fine) {
        :hover {
            color: ${theme.colors.blue5};
            background-color: ${theme.colors.blue1};

            > svg path {
                fill: ${theme.colors.blue5};
            }
        }

        :focus-visible {
            color: ${theme.colors.blue5};
            background-color: ${theme.colors.blue1};

            > svg path {
                fill: ${theme.colors.blue5};
            }
        }
    }
`;

export const navLinkStyles = css`
    ${dynamicStyles};
    text-transform: capitalize;
    color: ${theme.colors.gray5};
    transition:
        color 0.3s ease-in-out,
        background-color 0.3s ease-in-out;
    margin-bottom: 24px;
    padding: 24px;
    border-bottom: 1px solid ${theme.colors.gray3};

    @media (min-width: 1280px) {
        margin-bottom: 0 !important;
        padding: 32px 40px;
        border-left: 1px solid ${theme.colors.gray3};
        border-bottom: none;
    }
`;

export const subNavLinkStyles = css`
    ${navLinkStyles};

    /* website doesnt have sub nav links for now */
`;

export const navBtnStyles = css`
    ${plainBtnStyles};
    ${navLinkStyles};

    /* website doesnt have nav btns for now */

    > svg {
        margin-left: 16px;
        transition: transform 0.3s ease-in-out;
        flex-shrink: 0;
        transform: rotate(-90deg);

        path {
            transition: fill 0.3s ease-in-out;
            fill: ${theme.colors.gray5};
        }
    }
`;

export const subNavBtnStyles = css`
    ${plainBtnStyles};
    ${subNavLinkStyles};
    font-weight: 400;
    display: flex;
    align-items: center;
    width: 100%;
    text-align: left;

    /* website doesnt have sub nav btns for now */

    &.current-sub-category {
        color: ${theme.colors.blue4};
    }

    > svg {
        transform: rotate(-90deg);
        margin-left: 10px;
    }
`;
